import { BaseField, SelectionMarkField } from './azureOcr'
import { FormType as DocusignFormType } from './docusign'

export const form6Fields = [
    '10_Reappointment',
    '10_Reappointment2',
    '10_Reappointment_Day_1',
    '10_Reappointment_Day_2',
    '10_Reappointment_Month_1',
    '10_Reappointment_Month_2',
    '10_Reappointment_Year_1',
    '10_Reappointment_Year_2',
    '10_Reappointment_Year_3',
    '10_Reappointment_Year_4',
    '4.2_Continuing_Date_Day',
    '4.2_Continuing_Date_Month',
    '4.2_Continuing_Date_Year',
    '4.2_Single_EndDate_Day',
    '4.2_Single_EndDate_Month',
    '4.2_Single_EndDate_Year',
    '4.2_Single_StartDate_Day',
    '4.2_Single_StartDate_Month',
    '4.2_Single_StartDate_Year',
    '4.4_Instructions',
    '6_Agent_Date_Day',
    '6_Agent_Date_Month',
    '6_Agent_Date_Year',
    '6_Principal2_Date_Day',
    '6_Principal2_Date_Month',
    '6_Principal2_Date_Year',
    '6_Principal_Date_Day',
    '6_Principal_Date_Month',
    '6_Principal_Date_Year',
    '8.1_Advertising',
    '8.1_AuthAmt',
    '8.1_Date_Day',
    '8.1_Date_Month',
    '8.1_Date_Year',

    '8.2_Repairs',
    '8.2a_Repairs',
    '8.3_Amount',
    '8.3_Amount2',
    '8.3_Amount3',
    '8.3_Amount4',
    '8.3_Description',
    '8.3_Description2',
    '8.3_Description3',
    '8.3_Description4',
    '8.3_Payable',
    '8.3_Payable2',
    '8.3_Payable3',
    '8.3_Payable4',
    '8.4_Amount',
    '8.4_Amount2',
    '8.4_Amount3',
    '8.4_Amount4',
    '8.4_Service',
    '8.4_Service2',
    '8.4_Service3',
    '8.4_Service4',
    '8.4_Source',
    '8.4_Source2',
    '8.4_Source3',
    '8.4_Source4',
    '9_Attachments',
    'AgencyABN',
    'AgencyACN',
    'AgencyAddress',
    'AgencyAddress2',
    'AgencyFax',
    'AgencyLicenceeName',
    'Agency2LicenceNo',
    'Agency_LicenceExpiryDate_Day',
    'Agency_LicenceExpiryDate_Month',
    'Agency_LicenceExpiryDate_Year',
    'AgencyLicenceNo',
    'AgencyName',
    'AgencyPhone',
    'AgencyPostcode',
    'AgencyState',
    'AgencySuburb',
    'Agent_Auctioneer_CB',
    'AgentEmail',
    'Agent_Letting_CB',
    'Agent (Management/Leasing)_SigningDate',
    'AgentMobile',
    'Agent_NameFull_Signature2',
    'Agent_RealEstate_CB',
    'Agent (Sales)_SigningDate',
    'Agent_SignDate2_Day_1',
    'Agent_SignDate2_Day_2',
    'Agent_SignDate2_Month_1',
    'Agent_SignDate2_Month_2',
    'Agent_SignDate2_Year_1',
    'Agent_SignDate2_Year_2',
    'Agent_SignDate2_Year_3',
    'Agent_SignDate2_Year_4',
    'Agreement_End',
    'Agreement_End',
    'Appointment_Term_CB',
    'Appointment_Term_CB',
    'Auction_Day',
    'Auction_Month',
    'Auction_Year',
    'Client 1 (Management/Leasing)_SigningDate',
    'Client 1 (Sales)_SigningDate',
    'Client 2 (Management/Leasing)_SigningDate',
    'Client 2 (Sales)_SigningDate',
    'Commission_Other',
    'Commission_OtherTypes',
    'CommissionPaid_Other',
    'CommissionPaid_Sales',
    'Fee_Agent',
    'Listing_Type',
    'Listing_Type',
    'Listing_Type',
    'Premises_LegalDesc',
    'Premises_LotNo',
    'Premises_PlanNo',
    'Premises_Postcode',
    'Premises_State',
    'Premises_Street1',
    'Premises_Street2',
    'Premises_Suburb',
    'Premises_TitleRef',
    'Price_ElectronicListing',
    'Price_Letting_CB',
    'Price_List_CB',
    'Price_Reserve',
    'Price_Reserve_CB',
    'Principal2_ABN',
    'Principal2_ACN',
    'Principal2_ContactEmail',
    'Principal2_ContactFax',
    'Principal2_ContactHm',
    'Principal2_ContactMobile',
    'Principal2_GST',
    'Principal2_GST',
    'Principal2_NameFull',
    'Principal2_NameFull_Signature2',
    'Principal2_NameFull_Signature3',
    'Principal2_Postcode',
    'Principal2_SignDate2_Day_1',
    'Principal2_SignDate2_Day_2',
    'Principal2_SignDate2_Month_1',
    'Principal2_SignDate2_Month_2',
    'Principal2_SignDate2_Year_1',
    'Principal2_SignDate2_Year_2',
    'Principal2_SignDate2_Year_3',
    'Principal2_SignDate2_Year_4',
    'Principal2_SignDate3_Day_1',
    'Principal2_SignDate3_Day_2',
    'Principal2_SignDate3_Month_1',
    'Principal2_SignDate3_Month_2',
    'Principal2_SignDate3_Year_1',
    'Principal2_SignDate3_Year_2',
    'Principal2_SignDate3_Year_3',
    'Principal2_SignDate3_Year_4',
    'Principal2_State',
    'Principal2_Street1',
    'Principal2_Street2',
    'Principal2_Suburb',
    'Principal_ABN',
    'Principal_ACN',
    'Principal_ContactEmail',
    'Principal_ContactFax',
    'Principal_ContactHm',
    'Principal_ContactMobile',
    'Principal_GST',
    'Principal_GST',
    'Principal_NameFull',
    'Principal_NameFull_Signature2',
    'Principal_NameFull_Signature3',
    'Principal_Postcode',
    'Principal_SignDate2_Day_1',
    'Principal_SignDate2_Day_2',
    'Principal_SignDate2_Month_1',
    'Principal_SignDate2_Month_2',
    'Principal_SignDate2_Year_1',
    'Principal_SignDate2_Year_2',
    'Principal_SignDate2_Year_3',
    'Principal_SignDate2_Year_4',
    'Principal_SignDate3_Day_1',
    'Principal_SignDate3_Day_2',
    'Principal_SignDate3_Month_1',
    'Principal_SignDate3_Month_2',
    'Principal_SignDate3_Year_1',
    'Principal_SignDate3_Year_2',
    'Principal_SignDate3_Year_3',
    'Principal_SignDate3_Year_4',
    'Principal_State',
    'Principal_Street1',
    'Principal_Street2',
    'Principal_Suburb',
    'Pt6_AgentDate',
    'Pt6_Client1Date',
    'Pt6_Client2Date',
    'Service_Auction_CB',
    'Service_Collection_SO',
    'Service_CommercialLeasing_CB',
    'Service_Letting_SO',
    'Service_Management_SO',
    'Service_Other',
    'Service_Other_CB',
    'Service_PropertyManagement_CB',
    'Service_Purchase_CB',
    'Service_Sale_CB',

    'ReasonableSteps',
    'NoOtherAgentAppointed',
    'CMARequested',
    'AllowCMADisclosure',
    'ClientAckPrivacyNotice',
    'ClientAckS103',
    'ClientAckPriorToSign',
    'AgencyWebsite',
] as const

const ef016Fields = [
    'AgDisbBCLevies',
    'AgDisbBCLevies',
    'AgDisbGovtLevies',
    'AgDisbGovtLevies',
    'AgDisbOther',
    'AgDisbRepairs',
    'AgDisbRepairs',
    'AgencyWebsite',
    'Agent (Management/Leasing)_SigningDate',
    'Agent (Management/Leasing)_SigningDate',
    'AllowPets',
    'AllowPets',
    'AllowPetsCond',
    'Appliance1',
    'Appliance2',
    'Appliance3',
    'Appliance4',
    'BCManContactEmail',
    'BCManContactFax',
    'BCManContactWk',
    'BCManName',
    'BCManPostcode',
    'BCManState',
    'BCManStreet1',
    'BCManStreet2',
    'BCManSuburb',
    'BCSecContactEmail',
    'BCSecContactFax',
    'BCSecContactWk',
    'BCSecName',
    'BCSecPostcode',
    'BCSecState',
    'BCSecStreet1',
    'BCSecStreet2',
    'BCSecSuburb',
    'BuildingInsPolicyNo',
    'BuildingInsurer',
    'Client 1 (Management/Leasing)_SigningDate',
    'Client 1 (Management/Leasing)_SigningDate',
    'Client 2 (Management/Leasing)_SigningDate',
    'Client 2 (Management/Leasing)_SigningDate',
    'Client 3 (Management/Leasing)_SigningDate',
    'Client 3 (Management/Leasing)_SigningDate',
    'Client 4 (Management/Leasing)_SigningDate',
    'Client 4 (Management/Leasing)_SigningDate',
    'ClientAckPrivacyNotice',
    'ContentsInsPolicyNo',
    'ContentsInsurer',
    'Contractor1',
    'Contractor2',
    'Contractor3',
    'Contractor4',
    'ElecContactMobile',
    'ElecContactWk',
    'ElectricalContractor',
    'EOFYFeeAmount',
    'EOFYFeeDatePay',
    'ExpensesAdminAmount',
    'ExpensesAdminDescr',
    'ExpensesAdvertMarkAmount',
    'ExpensesOtherAmount1',
    'ExpensesOtherAmount2',
    'ExpensesOtherAmount3',
    'ExpensesOtherDetails1',
    'ExpensesOtherDetails2',
    'ExpensesOtherDetails3',
    'Frequency1',
    'Frequency2',
    'Frequency3',
    'Frequency4',
    'HasRegulatedPool',
    'HasRegulatedPool',
    'HasRegulatedPool',
    'IsWaterEfficient',
    'IsWaterEfficient',
    'LessorInsPolicyNo',
    'LessorInsurer',
    'LettingCommAmount',
    'LettingCommDatePay',
    'MaintRepairFeeAmount',
    'MaintRepairFeeDatePay',
    'MaterialFacts',
    'MediationTribFeeAmount',
    'MediationTribFeeDatePay',
    'MgtFeeAmount',
    'MgtFeeDatePay',
    'NoIndvWaterMeter',
    'NoOfBathrooms',
    'NoOfBedrooms',
    'NoOfDining',
    'NoOfDiningKitchen',
    'NoOfEnsuite',
    'NoOfGarage',
    'NoOfKitchen',
    'NoOfLaundry',
    'NoOfLounge',
    'NoOfLoungeDining',
    'NoOfMediaRoom',
    'NoOfOccupants',
    'NoOfOccupants2',
    'NoOfOtherRooms',
    'NoOfPantry',
    'NoOfRumpus',
    'NoOfSepShower',
    'NoOfSepToilets',
    'NoOfShed',
    'NoOfStudy',
    'NoOtherAgentAppointed',
    'OccupancyDate',
    'Other1ContactMobile',
    'Other1ContactWk',
    'Other2ContactMobile',
    'Other2ContactWk',
    'OtherContractor1',
    'OtherContractor2',
    'OtherFeeAmount1',
    'OtherFeeAmount2',
    'OtherFeeAmount3',
    'OtherFeeAmount4',
    'OtherFeeDatePay1',
    'OtherFeeDatePay2',
    'OtherFeeDatePay3',
    'OtherFeeDatePay4',
    'OtherFeeDetails1',
    'OtherFeeDetails2',
    'OtherFeeDetails3',
    'OtherFeeDetails4',
    'OtherRoomsDetails',
    'OwEmergencyContactEmail',
    'OwEmergencyContactHm',
    'OwEmergencyContactMobile',
    'OwEmergencyContactWk',
    'OwEmergencyName',
    'OwEmergencyPostcode',
    'OwEmergencyState',
    'OwEmergencyStreet1',
    'OwEmergencyStreet2',
    'OwEmergencySuburb',
    'PaymentsAndStatements',
    'PaymentsAndStatements2',
    'PaymentsAndStatementsPeriod',
    'PaymentsAndStatementsPeriod',
    'PaymentsAndStatementsPeriod2',
    'PaymentsAndStatementsPeriod2',
    'PaymentsAndStatementsPeriodOther',
    'PaymentsAndStatementsPeriodOther2',
    'PerInspFeeAmount',
    'PerInspFeeDatePay',
    'PLinsPolicyNo',
    'PLInsurer',
    'PlumbingContactMobile',
    'PlumbingContactWk',
    'PlumbingContractor',
    'PortablePoolCert',
    'PortablePoolCert',
    'PortablePoolCertCond',
    'Premises_CTSScheme',
    'Premises_Postcode',
    'Premises_Scheme',
    'Premises_State',
    'Premises_Street1',
    'Premises_Street2',
    'Premises_Suburb',
    'Principal2_NameFull',
    'Principal_BankAccBranch',
    'Principal_BankAccBranch2',
    'Principal_BankAccName',
    'Principal_BankAccName2',
    'Principal_BankAccNo',
    'Principal_BankAccNo2',
    'Principal_BankBSB',
    'Principal_BankBSB2',
    'Principal_BankName',
    'Principal_BankName2',
    'Principal_NameFull',
    'PrivatePoolCert',
    'PrivatePoolCert',
    'PrivatePoolCertCond',
    'ReasonableSteps',
    'RenewalNegFeeAmount',
    'RenewalNegFeeDatePay',
    'Rent_AmountorPercentage',
    'Rent_AmountorPercentage2',
    'RentPaidTo',
    'RentPaidTo',
    'RentPaidTo2',
    'RentPaidTo2',
    'Servicing1',
    'Servicing2',
    'Servicing3',
    'Servicing4',
    'SharedPoolCert',
    'SharedPoolCert',
    'SharedPoolCertCond',
    'SmokeAlarmContactMobile',
    'SmokeAlarmContactWk',
    'SmokeAlarmContractor',
    'SpecCond',
    'StatementProvided',
    'TenToPayElec',
    'TenToPayElec',
    'TenToPayElecPercent',
    'TenToPayGas',
    'TenToPayGas',
    'TenToPayGasPercent',
    'TenToPayOther',
    'TenToPayPhone',
    'TenToPayPhone',
    'TenToPayPhonePercent',
    'TitleSearchFeeAmount',
    'TitleSearchFeeDatePay',
    'Warranty1',
    'Warranty1ExpiryDate',
    'Warranty2',
    'Warranty2ExpiryDate',
    'Warranty3',
    'Warranty3ExpiryDate',
    'Warranty4',
    'Warranty4ExpiryDate',
    'WaterChargesKL',
    'WaterChargesMonth',
    'WaterChargesOtherInstr',
    'WaterChargesOtherInstrDetails',
]

export type Form6Field = (typeof form6Fields)[number]
export type Ef016Field = (typeof ef016Fields)[number]

const contractFields = [
    'AffectedByNDR',
    'Agency2ABN',
    'Agency2LicenceNo',
    'Agency2Name',
    'AgencyABN',
    'AgencyAddress',
    'AgencyAddress2',
    'AgencyFax',
    'AgencyLicenceNo',
    'AgencyName',
    'AgencyPeriod_EndDate',
    'AgencyPeriod_StartDate',
    'AgencyPhone',
    'AgencyPostcode',
    'AgencyState',
    'AgencySuburb',
    'AgentEmail',
    'AgentMobile',
    'BalanceDeposit',
    'BalanceDepositDate',
    'Bond_Amount',
    'BuildingPestInspDate',
    'Buyer (1)_SigningDate',
    'Buyer (1) Witness_SigningDate',
    'Buyer (2)_SigningDate',
    'Buyer (2) Witness_SigningDate',
    'Buyer_Agency2ABN',
    'Buyer_Agency2LicenceNo',
    'Buyer_Agency2Name',
    'Buyer_AgencyABN',
    'Buyer_AgencyAddress',
    'Buyer_AgencyAddress2',
    'Buyer_AgencyFax',
    'Buyer_AgencyLicenceNo',
    'Buyer_AgencyName',
    'Buyer_AgencyPhone',
    'Buyer_AgencyPostcode',
    'Buyer_AgencyState',
    'Buyer_AgencySuburb',
    'Buyer_AgentEmail',
    'Buyer_AgentMobile',
    'ContractDate',
    'DefInterestRate',
    'DepHolderTrustAcc',
    'DepositHolder',
    'DepositHolder_AccountNo',
    'DepositHolder_Bank',
    'DepositHolder_BSB',
    'EncumbYesNo',
    'EncumbYesNo',
    'FinAmount',
    'Financier',
    'FinDate',
    'GST_Withholding_CB',
    'GST_Withholding_CB',
    'GST_Withholding_Payment_CB',
    'GST_Withholding_Payment_CB',
    'HasPrivatePool',
    'HasPrivatePool',
    'InitialDeposit',
    'InitialDepositDate',
    'LandSoldAs',
    'LandSoldAsLeasehold',
    'Premises_Encumbrances',
    'Premises_ExclFixtures',
    'Premises_InclChattels',
    'Premises_LegalDescription',
    'Premises_LGA',
    'Premises_LotNo',
    'Premises_PlanNo',
    'Premises_Postcode',
    'Premises_SiteArea',
    'Premises_SpecCond',
    'Premises_State',
    'Premises_Street1',
    'Premises_Street2',
    'Premises_Suburb',
    'Premises_TitleRef',
    'Price_Sale',
    'Principal2_ABN',
    'Principal2_ContactEmail',
    'Principal2_ContactFax',
    'Principal2_ContactHm',
    'Principal2_ContactMobile',
    'Principal2_NameFull',
    'Principal2_Postcode',
    'Principal2_State',
    'Principal2_Street1',
    'Principal2_Street2',
    'Principal2_Suburb',
    'Principal_ABN',
    'Principal_ContactEmail',
    'Principal_ContactFax',
    'Principal_ContactHm',
    'Principal_ContactMobile',
    'Principal_NameFull',
    'Principal_Postcode',
    'PrincipalSol_ABN',
    'PrincipalSol_ContactEmail',
    'PrincipalSol_ContactFax',
    'PrincipalSol_ContactMobile',
    'PrincipalSol_ContactWk',
    'PrincipalSol_FirmName',
    'PrincipalSol_NameFull',
    'PrincipalSol_Postcode',
    'PrincipalSol_State',
    'PrincipalSol_Street1',
    'PrincipalSol_Street2',
    'PrincipalSol_Suburb',
    'Principal_State',
    'Principal_Street1',
    'Principal_Street2',
    'Principal_Suburb',
    'PrivatePoolCert',
    'PrivatePoolCert',
    'Properties.Condition_cont_bui',
    'Properties.Condition_cont_vac',
    'Purchaser2_ABN',
    'Purchaser2_ContactEmail',
    'Purchaser2_ContactFax',
    'Purchaser2_ContactHm',
    'Purchaser2_ContactMobile',
    'Purchaser2_NameFull',
    'Purchaser2_Postcode',
    'Purchaser2_State',
    'Purchaser2_Street1',
    'Purchaser2_Street2',
    'Purchaser2_Suburb',
    'Purchaser_ABN',
    'Purchaser_ContactEmail',
    'Purchaser_ContactFax',
    'Purchaser_ContactHm',
    'Purchaser_ContactMobile',
    'Purchaser_NameFull',
    'Purchaser_Postcode',
    'PurchaserSol_ABN',
    'PurchaserSol_ContactEmail',
    'PurchaserSol_ContactFax',
    'PurchaserSol_ContactMobile',
    'PurchaserSol_ContactWk',
    'PurchaserSol_FirmName',
    'PurchaserSol_NameFull',
    'PurchaserSol_Postcode',
    'PurchaserSol_State',
    'PurchaserSol_Street1',
    'PurchaserSol_Street2',
    'PurchaserSol_Suburb',
    'Purchaser_State',
    'Purchaser_Street1',
    'Purchaser_Street2',
    'Purchaser_Suburb',
    'Rent_Amount',
    'SafetySwitch',
    'SafetySwitch',
    'Seller (1)_SigningDate',
    'Seller (1) Witness_SigningDate',
    'Seller (2)_SigningDate',
    'Seller (2) Witness_SigningDate',
    'SettlementDate',
    'SettlementPlace',
    'SmokeAlarm',
    'SmokeAlarm',
    'Tenancy_ManAgency',
    'Tenancy_ManAgencyContactEmail',
    'Tenancy_ManAgencyContactFax',
    'Tenancy_ManAgencyContactMobile',
    'Tenancy_ManAgencyContactWk',
    'Tenancy_ManAgencyPostcode',
    'Tenancy_ManAgencyState',
    'Tenancy_ManAgencyStreet1',
    'Tenancy_ManAgencyStreet2',
    'Tenancy_ManAgencySuburb',
    'Tenancy_ManAgent',
    'TenancyPeriod_Term',
    'Tenant_NameFull',
]

export type ContractField = (typeof contractFields)[number]

const listingContacts = ['agent', 'vendors', 'buyers']
export type ListingContactType = (typeof listingContacts)[number]

export const FormTypes = ['agentAppointmentForm' /*'contractForm'*/] as const
export type FormType = (typeof FormTypes)[number]

export const getFormName = (formType: FormType | DocusignFormType) => {
    switch (formType) {
        case 'agentAppointmentForm':
            return 'Agent Appointment Form'
        case 'disclosureStatementForm':
            return 'Disclosure Statement'
        case 'fundDisbursementForm':
            return 'Fund Disbursement Form'
        case 'contractForm':
            return 'Contract'
    }
}

export interface ReiSignatureRecipient {
    name: string
    email: string
    status: 'sent' | 'delivered' | 'completed'
}

export interface ReiSignatureStatus {
    status: 'sent' | 'delivered' | 'completed'
    created: string
    updated: string
    sent: string
    recipients: ReiSignatureRecipient[]
}

export interface ReiForm {
    id: number
    createdByUserId: string
    template_version_id?: number
    syncError?: boolean
    formStatus: 'draft' | 'finalised' | 'sent' | 'completed' | 'manual upload'
    signedForm?: string
    signatureRecipients?: ReiSignatureRecipient[]
    attachments: {
        name: string
        s3Key: string
    }[]
    ocr?: any
    azureOcr?: Record<string, SelectionMarkField | BaseField>
    ocr_gpt?: any
}
